import Header from "../components/Header";
import Footer from "../components/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faGlobe, faLocationDot } from "@fortawesome/free-solid-svg-icons";
import album1 from "../assets/album1.png";


const Contact = () => {
  return (
    <>
      <div className="">
            <Header />
            <div className="mx-5 my-10 md:my-20 lg:mx-48">
            <h1 className="mt-5 text-3xl font-thin text-left md:mt-10 text-slate-500">Phauzdar's Album!</h1>
            {/* <h2 className="text-sm font-thin text-center text-gray-600">We are here to help you!</h2> */}
                <div className="mt-24">
                    <div className="relative h-auto md:h-96 bg-gray-50 border-[1px] border-slate-200 shadow-inner mt-6 rounded-lg">
                        <div className="hidden md:block bg-white rounded-full w-32 h-32 md:w-80 md:h-80 shadow-inner absolute -right-32 top-[8%] text-center items-center">
                            <img src={album1} alt="" className="w-32 h-32 rounded-full md:w-80 md:h-80" />
                        </div>

                        <div className="relative items-center w-32 h-auto text-center md:hidden">
                            <img src={album1} alt="" className="absolute w-32 h-32 -top-16 left-[90%] sm:left-64" />
                        </div>
                        
                        <div className="px-5 py-5 mt-12 text-left align-middle md:mt-0 md:px-10 md:py-16 md:pr-60">
                            <h2 className="pb-3 mt-12 text-xl font-semibold md:mt-0 text-slate-600">Lorem ipsum dolor sit</h2>
                            <h2 className="text-sm text-justify">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.<br /><br />
                            Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. <br /><br />
                            Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</h2>
                        </div>
                    </div>

                    <div className="mt-32 relative h-auto md:h-96 bg-gray-50 border-[1px] border-slate-200 shadow-inner md:mt-6 rounded-lg">
                        <div className="hidden md:block bg-white rounded-full w-32 h-32 md:w-80 md:h-80 shadow-inner absolute -right-32 top-[8%] text-center items-center">
                            <img src={album1} alt="" className="w-32 h-32 rounded-full md:w-80 md:h-80" />
                        </div>

                        <div className="relative items-center w-32 h-auto text-center md:hidden">
                            <img src={album1} alt="" className="absolute w-32 h-32 -top-16 left-[90%] sm:left-64" />
                        </div>
                        
                        <div className="px-5 py-5 mt-12 text-left align-middle md:pr-60 md:text-right md:mt-0 md:px-10 md:py-16">
                            <h2 className="pb-3 text-xl font-semibold text-slate-600">Phauzdar moved from Bangladesh</h2>
                            <h2 className="text-sm text-justify">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.<br /><br />
                            Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. <br /><br />
                            Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</h2>
                        </div>
                    </div>

                    <div className="relative h-auto md:h-96 bg-gray-50 border-[1px] border-slate-200 shadow-inner mt-32 md:mt-6 rounded-lg">
                        <div className="hidden md:block bg-white rounded-full w-32 h-32 md:w-80 md:h-80 shadow-inner absolute -right-32 top-[8%] text-center items-center">
                            <img src={album1} alt="" className="w-32 h-32 rounded-full md:w-80 md:h-80" />
                        </div>

                        <div className="relative items-center w-32 h-auto text-center md:hidden">
                            <img src={album1} alt="" className="absolute w-32 h-32 -top-16 left-[90%] sm:left-64" />
                        </div>
                        
                        <div className="px-5 py-5 mt-12 text-left align-middle md:mt-0 md:px-10 md:py-16 md:pr-60">
                            <h2 className="pb-3 text-xl font-semibold text-slate-600">After independence</h2>
                            <h2 className="text-sm text-justify">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.<br /><br />
                            Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. <br /><br />
                            Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</h2>
                        </div>
                    </div>                    
                    
                </div>
            </div>
            <Footer />
      </div>
    </>
  );
};

export default Contact;