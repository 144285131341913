import React from "react";
import guestbook01 from '../assets/guestbook01.jpg';
import guestbook02 from '../assets/guestbook02.jpg';
import guestbook03 from '../assets/guestbook03.jpg';
import guestbook04 from '../assets/guestbook04.jpg';
import guestbook05 from '../assets/guestbook05.jpg';
import guestbook06 from '../assets/guestbook06.jpg';
import Footer from "../components/Footer";
import Header from "../components/Header";


const Guestbook = () => {
    return (
        <>
        <div>
        <Header />
            <div className="px-5 py-20 md:px-10 lg:px-20">
                <h2 className="mb-5 text-3xl">Guest Book</h2>
                <div className="md:flex md:items-center md:space-x-10">
                    <div className="h-auto rounded-md shadow-lg w-full md:w-1/2 border-[10px] border-white"><img src={guestbook01} alt='' /></div>
                    <div className="h-auto rounded-md shadow-lg w-full md:w-1/2 border-[10px] border-white"><img src={guestbook02} alt='' /></div>
                </div>
                <div className="md:flex md:items-center md:space-x-10">
                    <div className="h-auto rounded-md shadow-lg w-full md:w-1/2 border-[10px] border-white"><img src={guestbook04} alt='' /></div>
                    <div className="h-auto rounded-md shadow-lg w-full md:w-1/2 border-[10px] border-white"><img src={guestbook05} alt='' /></div>
                </div>
                <div className="md:flex md:items-center md:space-x-10">
                    <div className="h-auto rounded-md shadow-lg w-full md:w-1/2 border-[10px] border-white"><img src={guestbook03} alt='' /></div>
                    <div className="h-auto rounded-md shadow-lg w-full md:w-1/2 border-[10px] border-white"><img src={guestbook06} alt='' /></div>
                </div>
            </div>
            <Footer />
        </div>
        </>
    )
};

export default Guestbook;