import Header from "../components/Header";
import Footer from "../components/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faGlobe, faLocationDot } from "@fortawesome/free-solid-svg-icons";


const Contact = () => {
  return (
    <>
      <div className="">
            <Header />
            <div className="mx-5 my-20 lg:mx-48">
            <h1 className="mt-10 text-3xl font-thin text-center text-slate-500">Get in Touch!</h1>
            <h2 className="text-sm font-thin text-center text-gray-600">We are here to help you!</h2>
                <div className="mt-24 md:items-center md:justify-between md:space-x-5 md:flex">
                    <div className="relative w-full px-10 md:w-1/3 h-52 bg-slate-100 border-[1px] border-slate-200 shadow-md rounded-lg">
                        <div className="bg-slate-600 rounded-full w-16 h-16 absolute -top-10 left-[42%] text-center items-center pt-3.5">
                            <FontAwesomeIcon icon={faGlobe} className="items-center text-4xl text-white " />
                        </div>
                        
                        <div className="px-10 py-16 text-left align-middle">
                            <h2 className="pb-3 text-xl text-slate-600">Visit Us</h2>
                            <h2 className="text-sm">www.phauzdar.com</h2>
                        </div>
                    </div>

                    <div className="relative w-full px-10 md:w-1/3 h-52 bg-slate-100 border-[1px] border-slate-200 shadow-md rounded-lg">
                        <div className="bg-slate-600 rounded-full w-16 h-16 absolute -top-10 left-[42%] text-center items-center pt-3.5">
                            <FontAwesomeIcon icon={faLocationDot} className="items-center text-4xl text-white " />
                        </div>
                        
                        <div className="px-10 py-16 text-left align-middle">
                            <h2 className="pb-3 text-xl text-slate-600">Find Us</h2>
                            <h2 className="text-sm">4425 Fortran Drive, <br />Suite 112-P,<br />
                            San Jose, CA 95134</h2>
                        </div>
                    </div>
                    
                    <div className="relative w-full px-10 md:w-1/3 h-52 bg-slate-100 border-[1px] border-slate-200 shadow-md rounded-lg">
                        <div className="bg-slate-600 rounded-full w-16 h-16 absolute -top-10 left-[42%] text-center items-center pt-3.5">
                            <FontAwesomeIcon icon={faEnvelope} className="items-center text-4xl text-white " />
                        </div>
                        
                        <div className="px-10 py-16 text-left align-middle">
                            <h2 className="pb-3 text-xl text-slate-600">Email Us</h2>
                            <h2 className="text-sm">info@phauzdar.com</h2>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
      </div>
    </>
  );
};

export default Contact;
