import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { setBuyOriginalType } from "../redux/art-slice";

const ArtItem = ({
  text,
  artId,
  artsizeHeight,
  artsizewidth,
  art,
  price,
  id,
}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const [isDelete, setisDelete] = useState(false);

  const deleteArt = () => {};
  console.log(location, "location");
  return (
    <>
      <div className="cursor-pointer group w-max">
        <Link to={`/${artId}`}>
          <div className="relative">
            <img
              src={art}
              alt={text}
              className="h-56 rounded-md shadow-xl w-80 sm:w-52 sm:h-40 md:w-64 md:h-52 xl:w-80 2xl:w-80 obeject-fit"
            />
            <div className="absolute hidden w-full h-full transform -translate-y-full lg:flex group-hover:bg-gradient-to-t from-black via-black to-transparent group-hover:scale-200 group-hover:duration-100 rounded-bl-md rounded-br-md group-hover:transition-opacity opacity-40"></div>
            <div className="absolute invisible hidden lg:flex group-hover:visible">
              <div className="px-5 font-semibold text-white transform -translate-y-16">
                <div>
                  <div className="truncate w-72">{text}</div>
                  <div className="">See More</div>
                </div>
              </div>
            </div>
          </div>
        </Link>
        <div className="px-2 pt-2 bg-slate-100 rounded-b-md">
          <div>
            <div className="flex justify-between">
              <div className="flex items-center space-x-2">
                <div className="text-sm text-gray-700 sm:text-xs">ID :</div>
                <div className="text-base font-semibold sm:font-medium sm:text-xs md:text-sm ">
                  {artId}
                </div>
              </div>
              <div className="flex items-center space-x-2">
                <div className="text-sm text-gray-700 sm:text-xs">Size :</div>
                <div className="text-base font-semibold text-gray-500 sm:text-xs md:text-sm">
                  {artsizeHeight} * {artsizewidth} cm
                </div>
              </div>
            </div>
            <div className="flex items-center space-x-2">
              <div className="text-base">Price:</div>
              <div className="font-medium text-yellow-800">{price}</div>
            </div>
            <div className="flex items-center justify-between space-x-2">
              {location.pathname !== "/admin" && (
                <div className="text-sm text-gray-700 sm:text-xs md:text-sm">
                  For:
                </div>
              )}
              {location.pathname !== "/admin" && (
                <div className="flex space-x-3">
                  <div>
                    <Link to={`/${artId}/checkout`}>
                      <div className="px-4 py-1 my-2 text-xs font-bold text-white uppercase bg-green-600 rounded-md cursor-pointer md:text-sm">
                        Print Copy
                      </div>
                    </Link>
                  </div>
                  <div>
                    <Link
                      to={`/${artId}/checkout`}
                      onClick={() => {
                        dispatch(
                          setBuyOriginalType({
                            buyOriginalType: true,
                          })
                        );
                      }}
                    >
                      <div className="px-2 py-1 my-2 text-xs font-bold uppercase border rounded-md cursor-pointer border-amber-600 md:text-sm text-amber-700">
                        Original Art
                      </div>
                    </Link>
                  </div>
                  {/* if purchased then show sold */}
                </div>
              )}
              {location.pathname === "/admin" && (
                <>
                  {!isDelete ? (
                    <div className="flex space-x-3">
                      <div className="bg-green-600  cursor-pointer text-xs md:text-sm   text-white rounded-sm font-bold px-2 my-2 py-0.5">
                        Edit
                      </div>
                      <div
                        onClick={() => {
                          setisDelete(true);
                        }}
                        className="border-red-600 border cursor-pointer text-xs md:text-sm  text-amber-700 rounded-sm font-bold px-2 my-2 py-0.5"
                      >
                        Delete
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="flex items-center space-x-8">
                        <div>Do you want to delete ?</div>
                        <div className="flex space-x-5">
                          <div
                            onClick={() => {
                              setisDelete(false);
                            }}
                            className="cursor-pointer "
                          >
                            No
                          </div>
                          <div
                            onClick={() => {
                              dispatch({
                                type: "DELETE_ART",
                                payload: {
                                  token,
                                  id: id,
                                },
                              });
                              setisDelete(false);
                            }}
                            className="text-red-600 cursor-pointer"
                          >
                            Yes
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ArtItem;
