import React, { useEffect, useState } from "react";
import phauzdarLogo from "../assets/phouzdar_logo.png";
import { useDispatch, useSelector } from "react-redux";
import ArtItem from "../components/ArtItem";

const AdminPage = () => {
  const dispatch = useDispatch();
  const formdata = new FormData();

  const { arts, isuploading } = useSelector((state) => state.art);
  console.log(isuploading, "isuploading");
  const [email, setemail] = useState();
  const [password, setpassword] = useState();
  const [authMsg, setauthMsg] = useState();

  const [newPicture, setnewPicture] = useState();
  const [artDeatil, setartDeatil] = useState({
    year: "",
    height: "",
    width: "",
    description: "",
    Price: "",
  });

  const { token } = useSelector((state) => state.auth);

  formdata.append("picture", newPicture);
  formdata.append("year", artDeatil?.year);
  formdata.append("height", artDeatil?.height);
  formdata.append("width", artDeatil?.width);
  formdata.append("description", artDeatil?.description);
  formdata.append("Price", artDeatil?.Price);

  console.log(arts, "arts");

  const login = (e) => {
    dispatch({
      type: "LOGIN_USER",
      payload: {
        email: email,
        password: password,
      },
    });
  };

  console.log(artDeatil, "art detail");

  return (
    <div className="px-10 py-5">
      <div>
        <img src={phauzdarLogo} alt="" />
        <div className="text-lg font-semibold">Admin View</div>
        {authMsg && <div>{authMsg}</div>}
      </div>

      {/* login  */}
      {!token && (
        <div>
          <div>
            <div>
              <label className="text-lg font-semibold text-gray-500 w-52">
                Email
              </label>
              <input
                type="text"
                name=""
                value={email}
                onChange={(e) => {
                  setemail(e.target.value);
                }}
                className="mx-7 border-b-2 border-amber-700 focus:outline-none"
              />
            </div>
            <label className="text-lg font-semibold text-gray-500 w-52">
              Password
            </label>
            <input
              type="password"
              name=""
              value={password}
              className="mx-10 border-b-2 border-amber-700 focus:outline-none"
              onChange={(e) => {
                setpassword(e.target.value);
              }}
            />
            <button
              onClick={login}
              className="bg-green-700 px-2 py-1 rounded-sm text-white"
            >
              Login
            </button>
          </div>
        </div>
      )}

      {token && (
        <div className="pt-20 flex justify-between">
          <div className="">
            {/* new art upload */}
            <div className="text-lg font-semibold text-gray-500 border-b-2 w-40 ">
              Add New Art
            </div>
            <div className="pt-3 space-y-2">
              <div>
                <div className="text-sm">Choose art image</div>
                <div className="flex space-x-2 items-center">
                  <input
                    type="file"
                    onChange={(e) => {
                      setnewPicture(e.target.files[0]);
                    }}
                  />
                  {newPicture && (
                    <div>
                      <div>Preview Image</div>
                      <img
                        src={newPicture && URL.createObjectURL(newPicture)}
                        alt=""
                        className="w-52 rounded-sm"
                      />
                    </div>
                  )}
                </div>
              </div>

              <div>
                <div className="text-sm">Which year this art created</div>
                <input
                  type="text"
                  name=""
                  value={artDeatil?.year}
                  onChange={(e) => {
                    setartDeatil({
                      ...artDeatil,
                      year: e.target.value,
                    });
                  }}
                  className=" border-b-2 border-amber-700 focus:outline-none"
                />
              </div>
              <div>
                <div className="text-sm">Height of the picture</div>
                <input
                  type="text"
                  value={artDeatil?.height}
                  onChange={(e) => {
                    setartDeatil({
                      ...artDeatil,
                      height: e.target.value,
                    });
                  }}
                  className=" border-b-2 border-amber-700 focus:outline-none"
                />
              </div>
              <div>
                <div className="text-sm">Width of the picture</div>
                <input
                  type="text"
                  value={artDeatil?.width}
                  onChange={(e) => {
                    setartDeatil({
                      ...artDeatil,
                      width: e.target.value,
                    });
                  }}
                  className=" border-b-2 border-amber-700 focus:outline-none"
                />
              </div>

              <div>
                <div className="text-sm">Description (if any)</div>
                <input
                  type="text"
                  value={artDeatil?.description}
                  onChange={(e) => {
                    setartDeatil({
                      ...artDeatil,
                      description: e.target.value,
                    });
                  }}
                  className=" border-b-2 border-amber-700 focus:outline-none"
                />
              </div>
              <div>
                <div className="text-sm">Price</div>
                <input
                  type="number"
                  value={artDeatil?.Price}
                  onChange={(e) => {
                    setartDeatil({
                      ...artDeatil,
                      Price: e.target.value,
                    });
                  }}
                  className=" border-b-2 border-amber-700 focus:outline-none"
                />
              </div>
              {!isuploading ? (
                <div
                  onClick={() => {
                    dispatch({
                      type: "CREATE_POST",
                      payload: {
                        body: formdata,
                        token,
                      },
                    });
                    setartDeatil({
                      year: "",
                      height: "",
                      width: "",
                      description: "",
                      Price: "",
                    });
                    setnewPicture();
                  }}
                  className="bg-green-700 px-2 py-1 text-white font-semibold w-min cursor-pointer"
                >
                  Create
                </div>
              ) : (
                <div className="bg-green-700 italic px-2 py-1 text-white font-semibold w-min cursor-pointer">
                  Creating...
                </div>
              )}
            </div>
          </div>
          {/* list of art */}

          <div className="grid grid-cols-3 gap-x-5 overflow-y-auto h-[90vh]">
            {arts?.map((art) => (
              <>
                <ArtItem
                  id={art?._id}
                  text={art?.description}
                  artId={art?.artId}
                  artsizeHeight={art?.height}
                  artsizewidth={art?.width}
                  art={art?.picture?.secure_url}
                  price={art?.Price}
                />
              </>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminPage;
