import axios from "axios";

export const createArtAction = async (payload) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/art`,
      payload.body,
      {
        headers: {
          Authorization: payload.token,
        },
      }
    );

    return response;
  } catch (error) {}
};

export const getAllArtAction = async () => {
  const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/art`);

  return response;
};

export const getArtAction = async (payload) => {
  const response = await axios.get(
    `${process.env.REACT_APP_BASE_URL}/art/artDetail?artId=${payload.artId}`
  );

  return response;
};

export const deleteArtAction = async (payload) => {
  const response = await axios.delete(
    `${process.env.REACT_APP_BASE_URL}/art/deleteart?id=${payload.id}`,
    {
      headers: {
        Authorization: payload.token,
      },
    }
  );

  return response;
};

export const paymentAction = async (payload) => {
  const response = await axios.post(
    `${process.env.REACT_APP_BASE_URL}/art/payment`,
    payload.body
  );
  console.log(response, "action payment");
  return response;
};

export const originalArtMailAction = async (payload) => {
  const response = await axios.post(
    `${process.env.REACT_APP_BASE_URL}/art/buyOriginalArt`,
    payload
  );

  return response;
};
