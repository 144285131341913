import Header from "../components/Header";
import Footer from "../components/Footer";
import phouzdar_photo2 from "../assets/artist2.jpg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faShareFromSquare,
  faStar,
  faThumbsUp,
} from "@fortawesome/free-regular-svg-icons";
import { faBagShopping, faPaintBrush } from "@fortawesome/free-solid-svg-icons";
import ArtItem from "../components/ArtItem";

const AboutUs = () => {
  return (
    <>
      <div className="">
        <Header />
        <div className="lg:flex ">
          {/* about phauzdar and banner */}
          <div className="relative ">
            {/* background and phauzdar image */}
            <div className="bg-gray-100 h-auto backdrop-blur-lg w-full  md:max-lg:max-w-screen-sm md:max-lg:mx-auto  lg:w-[23rem] px-10 mx-10  lg:mx-7 translate-y-20  rounded-lg rounded-br-xl">
              <img
                src={phouzdar_photo2}
                className="relative object-fill mx-auto transform -translate-y-20 border-8 border-white rounded-full w-52 h-52 "
                alt="Phauzdar "
              />
              <div className="relative z-10 flex-col justify-center -translate-y-16">
                <div className="mb-5 border-b-2 rounded-full">
                  <div className="flex justify-center font-sans text-2xl font-semibold text-gray-600">
                    Pranab Phauzdar{" "}
                  </div>

                  <div className="flex justify-center text-gray-600">
                    1957, Kolkata, India
                  </div>
                </div>

                <div className="pt-5">
                  <div className="text-lg text-teal-600 border-b-2">
                    Exhibitions
                  </div>
                  <div className="w-auto pt-2 space-y-2 overflow-y-auto h-96 lg:w-72">
                    <ExhibitionItem
                      exhibitionName="Group Exhibition at the Lalit Kala Academy New Delhi"
                      year="1978-1979"
                    />
                    <ExhibitionItem
                      exhibitionName="Solo Exhibition of colleges"
                      year="1985"
                    />
                    <ExhibitionItem
                      exhibitionName="Solo Exhibition oil paintings at the Academy of Fine Arts Kolkata"
                      year="1986"
                    />
                    <ExhibitionItem
                      exhibitionName="Jehangir Art Gallery Mumbai"
                      year="1986"
                    />
                    <ExhibitionItem
                      exhibitionName="Solo Exhibition of colleges"
                      year="1985"
                    />
                    <ExhibitionItem
                        exhibitionName="Group Show in British Paint Decor Service Kolkata"
                        year="1984"
                    />
                    <ExhibitionItem
                        exhibitionName="Exhibition of Young Artists with MF Husain at Tata Centre in Kolkata"
                        year="1984"
                    />
                    <ExhibitionItem
                        exhibitionName="Group Exhibitions in Birla Academy Kolkata"
                        year="1981, 1982, 1983"
                    />
                    <ExhibitionItem
                        exhibitionName="Group Exhibition Paris"
                        year="1990"
                    />
                    <ExhibitionItem
                        exhibitionName="Pandole Art Gallery Mumbai"
                        year="1991"
                    />
                    <ExhibitionItem
                        exhibitionName="Academy of Fine Arts, Kolkata"
                        year="2011"
                    />
                  </div>
                  {/* <div className="my-3 text-sm text-right text-blue-700 hover:underline"><a href="/aboutus">Read More</a></div> */}
                </div>
              </div>
            </div>
          </div>
          {/* list of art works */}
          <div className="w-full py-24 lg:py-5 ">
            <div className="">
              <div className="pb-5 text-2xl font-normal text-left text-gray-400">
                About <span className="text-black">Phauzdar</span>
              </div>
              <div className="pr-16 text-sm text-justify text-gray-500">
                <h2><b>Pranab Phauzder</b> studied Fine Arts in Kolkata and is an extremely modest artist who believes that his art should speak to the viewer and not his curriculum vitae. Phauzdar's collages are works of art that look like oil paintings. Each collage is so skillfully completed that you will be amazed at their captivating beauty.
                <br />
                <br />
                He started making collages initially due to economic constraints with newspaper cuttings but he soon mastered the technique and currently works on stunning masterpieces with different types of paper. He is a very <b>philosophical artist</b> and all his paintings have a rather deep social, religious and political meaning.
                <br />
                <br />
                Mr. Phauzdar participated in various artists' workshops throughout India. Mr. Phauzdar has won the <b>State Lalit Kala Academy Award in 1986</b>. Several of his paintings have been sold and are held in many private collections all over the world.
                <br />
                <br />
                It was 1971, Bangladesh was known as East Pakistan under unprecedented oppression of Pakistan Regime. Torture and brutality in the country was to such an extreme extent that it may have exceeded the atrocities in <b>Europe and Africa</b>. West Pakistan's military machinery was killing intellects of the country by truckloads. The land soaked wet, rivers red with the bloody violence; the skies were smoky and dark from the burning villages. Amidst all these, a 14 year old boy held a riffle and stood by the side of his father to fight the Pak-army (Pakistan army referred to as Pak army at that time), he painted his deepest image of the atrocity, torture and barbaric brutality against women and children by the Pak-army.
                <br />
                <br />
The boy's painting inspired many thousands to hold arms to fight for freedom. The Pak-army aware of that painting, searched for that 14 year old boy and his painting. They found the painting in Parijana- a village in Shahzadpur, and burnt the whole village with his painting. The painting's story spread with the fire, smoke and cries of the village to many thousands who then turned into <b>'muktijoddha'</b> (freedom fighter). I am not sure if that boy knew about Guernica - the mural art of <b>Picasso,</b> but I am certainly sure that the 14 year old boy's work had produced the impact that <b>Picasso</b> would have dreamed of.
<br />
                <br />
That 14 year old boy is Pranab Phauzdar, he is the same famous artist Phauzdar. We would like to present to you Pranab Phauzdar through many of his inspirational and exceptional art work. Phauzdar not only fought and won against the Pak army but also constantly fought and is still fighting the invisible collective enemy of his own - poverty and politics. His life is a canvas and his canvases are his life's essences. We see in his artwork the deepest expression of life and the continuous fight for freedom, justice and equality.
<br />
                <br />
After independence, Phauzdar moved from Bangladesh to Kolkata, India, where he dealt with his teenage memories in Shahzadpur through his artwork while fighting the new frontier - poverty in the new land. Life before 1971 in Shahzadpur was as green as it was during <b>Rabindranath Tagore's</b> time. One of the prized memories that Phauzdar carried with him when he left Bangladesh was his first art exhibition that was held at <b>Rabindranath Tagore's</b> House in Shahzadpur. It has been said and reported that several of his paintings brought tears in eyes of the viewers because of the uncompromising truth and the reality in his work. From time to time, one may relate to Phauzdar's work with his teenage life's smiles and laughter.
<br />
                <br />
<b>Ganesh Pyne</b> once saw Phauzdar carrying a stack of blank canvas on his shoulder and commented that it looked astounding, like still-life picture. It is the same <b>Ganesh Pyne</b> who sponsored his art work for the first exhibition in Kolkata. <b>Ganesh Pyne</b> once asked Phauzdar where was the pain, Phauzdar replied, the 'He' knows.
<br />
                <br />
Phauzdar's works are so unique that each and every contemporary artist takes a pause to make comments. <b>M.F Hussain</b> ended-up buying Phauzdar's art for his private collection while attending an Art show by a group of rebellious artist in Kolkata.
<br />
                <br />
Phauzdar hid himself from the public for 12 years - a self imposed exile <b>"Banbas"</b>. During these 12 years he experimented with his unique technique of collage - that is hard to distinguish from liquid paint. His use of collage is another proof of the age-old doctrine - "necessity is the mother of invention". He moved to colored paper when his pocket dried out of paint and large stashes of discarded colored tissue paper from 'Durga-pooja' festival was available for him.
<br />
                <br />
Now, though Phauzdar no longer worries about money or food, he continues to work with colored paper - the collage for his best works. The more we see his work, the more we understand the fighter in him.</h2>
            </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default AboutUs;

export const ExhibitionItem = ({ exhibitionName, year }) => {
  return (
    <div className="flex items-center space-x-4 border-b">
      <FontAwesomeIcon icon={faPaintBrush} className="text-yellow-500" />
      <div className="text-base w-96">{exhibitionName}</div>
      <div className="w-40 font-semibold text-stone-700">{year}</div>
    </div>
  );
};
