import React, { useEffect } from "react";
// import Routes from "./Routes";

import Routes from "./Routes";
import { useDispatch, useSelector } from "react-redux";

const App = () => {
  const dispatch = useDispatch();
  const { arts } = useSelector((state) => state.art);

  useEffect(() => {
    if (arts === undefined) {
      dispatch({
        type: "ALL_ART",
      });
    }
  }, []);

  return <Routes />;
};

export default App;
